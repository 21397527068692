

























































import Vue from 'vue';
import SettingsDoctorCard from '@/components/cards/SettingsDoctorCard.vue';
import {boolean} from "boolean";

export default Vue.extend({
  name: 'SettingsDialogDoctors',
  components: {
    SettingsDoctorCard
  },
  props: {
    doctors: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      showDialog: false,
      newTreatment: '',
      doctorColor: undefined,
      doctorName: undefined,
      updatingDoctor: null,
      dialogType: null,
      swatches: [
        ['#56C2DE',
        '#5047A5'],
        ['#F29E41',
        '#21BA33'],
        ['#DE435B',
        '#1976A8'],
        ['#19A881',
        '#9719A8'],
        ['#FFA558',
        '#82C795']
      ],
    };
  },
  mounted() {
    this.$store.dispatch('LOAD_TEMPLATES');
  },
  computed: {
    filteredTreatments() {
      if (!this.updatingDoctor) return [];
      const dr = this.doctors.find(d => d.uuid === this.updatingDoctor);
      const drTreatments = dr.treatments.map(t => t.uuid);
      return this.treatments.filter(t => !drTreatments.includes(t.uuid));
    },
    treatments() {
      return this.$store.state.treatments;
    }
  },
  methods: {
    handleShowDialog(payload) {
      this.showDialog = true;
      this.updatingDoctor = payload.uuid;
      this.dialogType = payload.type;
      this.doctorColor = payload.color?.hex ?? payload.color;
      this.doctorName = payload.name ?? 'No name';
    },
    setAllowedToClose(value: boolean) {
      this.$emit('set-allowed-close', value);
    },
    async handleAddTreatment(type) {
      const payload = {
        doctorId: this.updatingDoctor,
        status: 'add',
        params: { treatment: this.newTreatment }
      };

      const success = await this.$store.dispatch('UPDATE_DOCTOR_TREATMENT', payload);

      if (success) {
        this.showDialog = false;
        this.$eventBus.$emit('emit-success', { state: true, text: 'Added treatment to practitioner.' });
        await this.$store.dispatch('LOAD_DOCTORS');
      } else {
        this.$eventBus.$emit('emit-error', { state: true });
      }
    },
    async handleAddAllTreatments({ uuid }) {
      this.updatingDoctor = uuid
      const successArr = await Promise.all(
        this.filteredTreatments.map(async x =>
          this.$store.dispatch('UPDATE_DOCTOR_TREATMENT', {
            doctorId: uuid,
            status: 'add',
            params: { treatment: x.uuid }
          })
        )
      )

      if (!successArr.every(x => x)) {
        this.$eventBus.$emit('emit-error', { state: true })
      } else {
        this.$eventBus.$emit('emit-success', { state: true, text: 'Added treatments to practitioner.' });
      }

      await this.$store.dispatch('LOAD_DOCTORS');
    },
    async handleUpdateColor() {
      const payload = {
        doctorId: this.updatingDoctor,
        params: { color: this.doctorColor, name: this.doctorName }
      };

      const success = await this.$store.dispatch('UPDATE_DOCTOR_SETTINGS', payload);

      if (success) {
        this.showDialog = false;
        this.$eventBus.$emit('emit-success', { state: true, text: 'Updated color.' });
        await this.$store.dispatch('LOAD_DOCTORS');
      } else {
        this.$eventBus.$emit('emit-error', { state: true });
      }
    }
  }
});
