
import Vue from "vue";
import {mapGetters} from 'vuex';

export default Vue.extend({
  name: "DoctorBookingInfo",
  props: {
    doctor: Object,
  },
  data() {
    return {
      dialog: true,
      appendIcon: undefined,
      preview: false,
      doctorBookingObjects: [],
    };
  },
  mounted() {
    this.doctorBookingObjects = this.getDoctorBooking(this.doctor.externalId);
  },
  computed: {
    ...mapGetters(['getDoctorBooking'])
  },
  methods: {
    async copyDoctorLink(bookingDocObject) {
      const slug = this.createSlug(this.doctor.externalId, bookingDocObject.templateId, bookingDocObject.name);
      const url = `${window.location.origin}/booking/new?doctor=${slug}`;
      await navigator.clipboard.writeText(url);
      this.$eventBus.$emit('emit-success', { state: true, text: 'URL copied' });
    },
    createSlug(
        externalId: number,
        templateId: number,
        doctorName: string
    ): string {
      // Remove symbols and replace spaces with underscores
      const slug = doctorName
          .replace(/[^\w\s]/gi, "") // Remove symbols
          .replace(/\s+/g, "_") // Replace spaces with underscores
          .toLowerCase(); // Convert to lowercase

      return `${externalId}-${templateId}-${slug}`;
    },
  },
});
